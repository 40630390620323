import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from "querystring";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { CURRENCY, MIN_SEARCH_LENGTH } from 'src/data/constants';
import useSearch from "src/modules/search/hooks/useSearch";
import { getProductUrl } from 'src/modules/ubercatalog';
import useLang from "src/services/language/useLang";
import useLocalize from 'src/services/localize/useLocalize';
import { disableBodyScroll, enableBodyScroll, isBodyScrollDisabled } from 'src/utils/system/scrollLock';
import { Place, sendOpenSearch, sendSearchFull, sendSearchStart } from '../../helpers/analytics';
import { SearchInputBaseProps } from '../SearchInput/SearchInput';
import { SearchBoxProps } from './SearchBox';
import { SearchResultsProductRowProps } from './SearchResults/SearchResultsProductRow';

interface IQuery extends ParsedUrlQuery {
  q?: string;
  category_id: string;
}

export type SearchResultProduct = Omit<SearchResultsProductRowProps, 'onClick'>

const useSearchBox = (place: Place): Omit<SearchBoxProps, 'variant'> => {
  const router = useRouter();
  const { q } = router.query as IQuery;

  const initialInputValue = q || '';
  const [searchBoxIsActive, setSearchBoxIsActive] = useState(false);

  const [searchString, setSearchString] = useState('');
  const [inputValue, setInputValue] = useState(initialInputValue);

  const inputRef = useRef<HTMLInputElement>(null);

  const localize = useLocalize();
  const lang = useLang();
  const {
    notFound,
    fetching,
    products,
    categories,
    count,
  } = useSearch({ searchValue: searchString, language: lang });

  useEffect(() => {
    return () => {
       // Check if body scroll is currently disabled
      if (isBodyScrollDisabled()) {
        // If scroll is disabled, enable it back on unmount
        enableBodyScroll();
      }
    };
  }, []);

  // Effect to trigger analytics when search results are fetched
  useEffect(() => {
    if (!fetching && count > 0 || notFound) {
      sendSearchFull({
        place,
        searchString,
        searchResultCount: count,
      });
    }
  }, [fetching, count, searchString, place, notFound]);

  // Effect to trigger search start analytics when the user starts typing
  useEffect(() => {
    if (searchString.length >= MIN_SEARCH_LENGTH) {
      sendSearchStart({
        place,
        searchString,
      });
    }
  }, [searchString, place]);

  const updateSearchString = useMemo(() => debounce(setSearchString, 400), []);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchString = event.target.value;

    setInputValue(searchString);
    updateSearchString(searchString);
  };

  const clearSearchState = () => {
    setInputValue('');
    updateSearchString('');
  };

  const disableSearchBox = () => {
    setSearchBoxIsActive(false);
    enableBodyScroll();

    if (!router.query.q) {
      setInputValue(initialInputValue);
    }
  };

  const clearSearch = () => {
    inputRef.current?.focus();

    clearSearchState();
  };

  const clearAndCloseSearchBox = () => {
    clearSearchState();
    disableSearchBox();
  };

  const openSearchPage = (value: string) => {
    disableSearchBox();
    const encodedInputValue = encodeURIComponent(value);
    const href = `/${lang}/search/?q=${encodedInputValue}`;
    router.push(href);
  };

  const handleInputFocus = () => {
    sendOpenSearch(place);

    if (!searchBoxIsActive) {
      setSearchBoxIsActive(true);
      disableBodyScroll();
    }

    updateSearchString(inputValue);
  };

  const handleInputClick = () => {
    if (!inputValue) {
      setSearchBoxIsActive(true);
    }
  };

  const inputProps: SearchInputBaseProps = {
    ref: inputRef,
    placeholder: localize('search_box_placeholder'),
    value: inputValue,
    onChange: handleInputChange,
    onFocus: handleInputFocus,
    onClick: handleInputClick,
    maxLength: 100,
  };

  const searchResultProducts: SearchResultProduct[] = useMemo(() => products.map(p => ({
    imagesSrc: p.img?.s150x150,
    priceFrom: p.price_details.price_from,
    priceTo: p.price_details.price_to,
    title: p.title,
    volume: p.volume,
    itemInStock: p.in_stock,
    unit: p.unit,
    currency: CURRENCY,
    weight: p.weight,
    bundle: p.bundle,
    url: getProductUrl({ lang, ean: p.ean, slug: p.slug }),
  })), [lang, products]);

  return {
    isNotFounded: notFound,
    isFetching: fetching,
    products: searchResultProducts,
    categories,
    count,
    searchBoxIsActive,
    inputProps,
    searchString,
    disableSearchBox,
    clearSearch,
    clearAndCloseSearchBox,
    openSearchPage,
    place,
  };
};

export default useSearchBox;
